.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}